<template>
  <div>
    <b-skeleton-table
      v-if="loading.list"
      :rows="3"
      :columns="6"
      :table-props="{ striped: true }"
    ></b-skeleton-table>
    <div v-else>
      <div v-if="success.list">
        <!-- show table if respon code 200 / success = true -->
        <div class="table-responsive" v-if="xhr.list.totalresults !== 0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nama</th>
                <th class="d-none d-sm-table-cell">Phone Number</th>
                <th class="d-none d-sm-table-cell">Company Name</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, key) in xhr.list.contacts.contact" :key="key">
                <td>{{ row.id }}</td>
                <td>
                  <span v-html="row.firstname + ' ' + row.lastname"></span>
                  <br />
                  <small>{{ row.email }}</small>
                </td>
                <td class="d-none d-sm-table-cell">{{ row.phonenumber }}</td>
                <td class="d-none d-sm-table-cell">{{ row.companyname }}</td>
                <td>
                  <button
                    class="btn btn-success btn-sm"
                    @click="move_edit(row)"
                  >
                    <i class="fa fa-edit"></i>
                    <span class="d-none d-sm-inline">edit</span>
                  </button>
                  &nbsp;
                  <button class="btn btn-danger btn-sm" @click="hapus(row.id)">
                    <i class="fa fa-trash"></i>
                    <span class="d-none d-sm-inline">hapus</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="alert alert-warning">No records found</div>
      </div>
      <div v-else>
        <b-alert show variant="danger">{{ xhr.list.message }}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { notif, loading, ApiURL } from "@/common/mixins/general.js";
export default {
  mixins: [notif, loading, ApiURL],
  data() {
    return {
      loading: {
        list: true
      },
      xhr: {
        list: {}
      },
      success: {
        list: false
      }
    };
  },
  methods: {
    /**
     * ------------------------------------------------------
     * hapus contact sub account
     * ------------------------------------------------------
     */
    hapus: function(id) {
      this.mx_swal_confirm("Data tidak dapat dikembalikan!").then(() => {
        this.mx_loading();
        this.loading.list = true;
        this.xhr_user
          .delete("contact", { contactid: id })
          .then(({ data }) => {
            this.mx_swal(data.message, "", "success");
          })
          .catch(({ response }) => {
            this.mx_swal(response.data.message);
          })
          .finally(() => {
            this.list();
            this.mx_loading(false);
            this.loading.list = false;
          });
      });
    },

    /**
     * ------------------------------------------------------
     * list contact sub account
     * ------------------------------------------------------
     */
    list: function() {
      this.xhr_user
        .get("contact")
        .then(({ data }) => {
          this.xhr.list = data.data.contact;
          this.success.list = true;
        })
        .catch(({ response }) => {
          this.xhr.list = response.data;
          this.$emit("childToParent", {
            name: "error",
            account: response.data
          });
        })
        .finally(() => {
          this.loading.list = false;
        });
    },

    /**
     * ------------------------------------------------------
     * ganti view component ke edit
     * ------------------------------------------------------
     */
    move_edit: function(payload) {
      this.$emit("childToParent", { name: "subEdit", account: payload });
    }
  },

  mounted() {
    this.list();
  }
};
</script>
