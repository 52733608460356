<template>
  <b-form @submit.stop.prevent="save_changes">
    <div class="row">
      <div class="col-sm-6">
        <b-form-group label="First Name:">
          <b-form-input
            v-model.trim="$v.form.firstname.$model"
            :state="validateState('firstname')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.firstname.$error">
            Firstname is required and at least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label="Last Name:">
          <b-form-input
            v-model.trim="$v.form.lastname.$model"
            :state="validateState('lastname')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.lastname.$error">
            Lastname is required and at least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <b-form-group label="Address 1:">
          <b-form-input
            v-model.trim="$v.form.address1.$model"
            :state="validateState('address1')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.address1.$error">
            Address is required and at least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label="Address 2:">
          <b-form-input
            v-model.trim="$v.form.address2.$model"
            :state="validateState('address2')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.address2.$error">
            At least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <b-form-group label="Company Name:">
          <b-form-input
            v-model.trim="$v.form.companyname.$model"
            :state="validateState('companyname')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.companyname.$error">
            At least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label="Email Address:">
          <b-form-input
            type="email"
            v-model.trim="$v.form.email.$model"
            :state="validateState('email')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.email.$error">
            Email is required, Must be valid email and at least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <b-form-group label="Phone Number :">
          <b-form-input
            v-model.trim="$v.form.phonenumber.$model"
            :state="validateState('phonenumber')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.phonenumber.$error">
            Phonenumber is required and at least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label="City:">
          <b-form-input
            v-model.trim="$v.form.city.$model"
            :state="validateState('city')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.city.$error">
            City is required and at least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <b-form-group label="State/Region:">
          <b-form-input
            v-model.trim="$v.form.state.$model"
            :state="validateState('state')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.state.$error">
            State is required and at least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label="Zip Code:">
          <b-form-input
            type="number"
            v-model.trim="$v.form.postcode.$model"
            :state="validateState('postcode')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.postcode.$error">
            Zip code is required, at least 4 character and just a number.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <b-form-group label="Tax ID :">
          <b-form-input
            v-model.trim="$v.form.tax_id.$model"
            :state="validateState('tax_id')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.tax_id.$error">
            At least 4 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label="Country:">
          <b-form-select
            v-model="form.country"
            :options="country"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <b-form-group label="Password :">
          <b-form-input
            type="password"
            v-model.trim="$v.form.password.$model"
            :state="validateState('password')"
          ></b-form-input>
          <strengthMeter
            :strength-meter-only="true"
            v-model="form.password"
          ></strengthMeter>
          <b-form-invalid-feedback v-if="$v.form.password.$error">
            At least 6 character.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label="Password Confirm:">
          <b-form-input
            type="password"
            v-model.trim="$v.form.passwordconfirm.$model"
            :state="validateState('passwordconfirm')"
          ></b-form-input>
          <b-form-invalid-feedback v-if="$v.form.passwordconfirm.$error">
            Password confirm not match with password.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <b-form-checkbox
          v-model="tick_to_configure"
          :value="true"
          :unchecked-value="false"
          @change="trigger_set_permission"
        >
          Tick to configure as a sub-account with client area access
        </b-form-checkbox>
      </div>

      <div v-if="tick_to_configure" class="col-sm-12 my-2">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <b-form-checkbox-group v-model="permissions">
                  <b-form-checkbox value="profile"
                    >Modify Master Account Profile</b-form-checkbox
                  >
                  <br />
                  <b-form-checkbox value="products"
                    >View Products & Services</b-form-checkbox
                  >
                  <br />
                  <b-form-checkbox value="managedomains"
                    >Manage Domain Settings</b-form-checkbox
                  >
                  <br />
                  <b-form-checkbox value="contacts"
                    >View & Manage Contacts</b-form-checkbox
                  ><br />
                </b-form-checkbox-group>
              </div>
              <div class="col-sm-6">
                <b-form-checkbox-group v-model="permissions">
                  <b-form-checkbox value="manageproducts"
                    >View & Modify Product Passwords</b-form-checkbox
                  ><br />
                  <b-form-checkbox value="domains"
                    >View Domains</b-form-checkbox
                  >
                  <br />
                  <b-form-checkbox value="invoices"
                    >View & Pay Invoices</b-form-checkbox
                  >
                </b-form-checkbox-group>

                <button
                  class="btn btn-sm btn-secondary my-2"
                  @click="check_permission()"
                  type="button"
                >
                  {{ check_all ? "Uncheck All" : "Check All" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- email preferences -->
    <div class="row my-5">
      <div class="col-sm-12">
        <h3>Email Preferences</h3>
        <b-form-checkbox
          v-model="form.generalemails"
          :value="1"
          :unchecked-value="0"
        >
          <b>General Emails</b> - General Announcements & Password Reminders
        </b-form-checkbox>
        <b-form-checkbox
          v-model="form.productemails"
          :value="1"
          :unchecked-value="0"
        >
          <b>Product Emails</b> - Order Details, Welcome Emails, etc...
        </b-form-checkbox>
        <b-form-checkbox
          v-model="form.domainemails"
          :value="1"
          :unchecked-value="0"
        >
          <b>Domain Emails</b> - Renewal Notices, Registration Confirmations,
          etc...
        </b-form-checkbox>
        <b-form-checkbox
          v-model="form.invoiceemails"
          :value="1"
          :unchecked-value="0"
        >
          <b>Invoice Emails</b> - Invoices & Billing Reminders
        </b-form-checkbox>
        <b-form-checkbox
          v-model="form.supportemails"
          :value="1"
          :unchecked-value="0"
        >
          <b>Support Emails</b> - Allow this user to open tickets in your
          account
        </b-form-checkbox>
      </div>
    </div>

    <button class="btn btn-primary">Save changes</button> &nbsp;
    <button type="button" @click="back_to_list" class="btn btn-secondary">
      Cancel
    </button>
  </b-form>
</template>

<script>
import negara from "@/assets/rw/country.json";
import strengthMeter from "vue-password-strength-meter";
import { validationMixin } from "vuelidate";
import { notif, loading, ApiURL } from "@/common/mixins/general.js";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin, notif, loading, ApiURL],
  components: { strengthMeter },
  props: {
    dataEdit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      check_all: false,
      tick_to_configure: false,
      permissions: [],
      country: negara.option,
      form: {
        contactid: this.dataEdit.id,
        firstname: this.dataEdit.firstname,
        lastname: this.dataEdit.lastname,
        companyname: this.dataEdit.companyname,
        email: this.dataEdit.email,
        address1: this.dataEdit.address1,
        address2: this.dataEdit.address2,
        city: this.dataEdit.city,
        state: this.dataEdit.state,
        postcode: this.dataEdit.postcode,
        country: this.dataEdit.country,
        phonenumber: this.dataEdit.phonenumber,
        tax_id: this.dataEdit.tax_id,
        generalemails: this.dataEdit.generalemails,
        productemails: this.dataEdit.productemails,
        domainemails: this.dataEdit.domainemails,
        invoiceemails: this.dataEdit.invoiceemails,
        supportemails: this.dataEdit.supportemails,
        permissions: this.dataEdit.permissions,
        password: "",
        passwordconfirm: ""
      }
    };
  },
  validations: {
    form: {
      firstname: { required, minLength: minLength(4) },
      lastname: { required, minLength: minLength(4) },
      companyname: { minLength: minLength(4) },
      email: { required, minLength: minLength(4), email },
      address1: { required, minLength: minLength(4) },
      address2: { minLength: minLength(4) },
      city: { required, minLength: minLength(4) },
      state: { required, minLength: minLength(4) },
      postcode: { required, minLength: minLength(4) },
      phonenumber: { required, minLength: minLength(4) },
      tax_id: { minLength: minLength(4) },
      password: { minLength: minLength(8) },
      passwordconfirm: { sameAsPassword: sameAs("password") }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    back_to_list: function() {
      this.$emit("childToParent", { name: "subList" });
    },
    /**
     * -------------------------------------------------
     * create sub account
     * -------------------------------------------------
     */
    save_changes: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.form.permissions = this.permissions.join();
      this.mx_loading();
      this.xhr_user
        .put("contact", this.form)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
          this.back_to_list();
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    },

    trigger_set_permission() {
      if (!this.tick_to_configure) {
        this.permissions = [];
        this.check_all = false;
      }
    },

    /**
     * ---------------------------------------------
     * check all permission toggle
     * ---------------------------------------------
     */
    check_permission: function() {
      this.check_all = !this.check_all;
      if (this.check_all) {
        this.permissions = [
          "profile",
          "products",
          "managedomains",
          "contacts",
          "manageproducts",
          "domains",
          "invoices"
        ];
      } else {
        this.permissions = [];
      }
    }
  },
  mounted() {
    /**
     * ---------------------------------------------
     * set auto fill checkbox permission
     * ---------------------------------------------
     */
    this.permissions = this.dataEdit.permissions.split(",");
    if (this.permissions.length !== 0) {
      this.tick_to_configure = true;
    }

    /**
     * ---------------------------------------------
     * check apakah semuanya terceklist, asumsi 7
     * ---------------------------------------------
     */
    if (this.permissions.length === 7) {
      this.check_all = true;
    }
  }
};
</script>
