<template>
  <RWCard title="Sub Account">
    <template #toolbar>
      <button
        class="btn btn-primary"
        @click="view = 'subAdd'"
        v-if="show_btn_tambah"
      >
        <i class="fa fa-plus-circle"></i> Tambah
      </button>
    </template>
    <template #body>
      <component
        :is="view"
        v-on:childToParent="handleFromChild"
        :dataEdit="data_edit"
      ></component>
    </template>
  </RWCard>
</template>

<script>
import subList from "@/views/pages/profile/sub-akun/sub-list.vue";
import subAdd from "@/views/pages/profile/sub-akun/sub-tambah.vue";
import subEdit from "@/views/pages/profile/sub-akun/sub-edit.vue";

import RWCard from "@/views/partials/content/Portlet.vue";
import { breadcrumb } from "@/common/mixins/general.js";
export default {
  mixins: [breadcrumb],
  components: { RWCard, subList, subAdd, subEdit },
  data() {
    return {
      view: "subList",
      data_edit: {},
      show_btn_tambah: true
    };
  },
  methods: {
    /**
     * -------------------------------------------
     * trigger child merubah component
     * payload {name : 'namaComponent'}
     * -------------------------------------------
     */
    handleFromChild: function(payload) {
      if (payload.name === "error") {
        this.show_btn_tambah = false;
      } else {
        this.show_btn_tambah = payload.name === "subList";
        this.view = payload.name;
        this.data_edit = payload.account;
      }
    }
  },
  mounted() {
    this.set_breadcrumb([
      { title: "Profile", route: "profile" },
      { title: "Sub Account", route: "profile/sub-account" }
    ]);
  }
};
</script>
